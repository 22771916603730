// src/components/DataTable.js
import "./LandTable.css"
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {fetchLands} from '../../utils/api'

const LandsTable = () => {
  const { guild } = useParams();
  const [LandInfo, setLandInfo] = useState([])
  useEffect(() => {
    const loadLandsInfo = async () => {
      try {
        const data = await fetchLands(guild);
        setLandInfo(data.result.res);
        console.log(data.result.res)
      } catch (error) {
        console.error('Error fetching room history:', error);
        // Handle error as needed (e.g., show error message)
      }
    };

    loadLandsInfo();
  }, [guild]);
  // if (loading) {
  //   return <p>Loading data...</p>;
  // }

  const sumIndustries = (a, b) => {
    // console.log({a,b})
    let first  = 0
    let second = 0
    if (a!==undefined) {
      first = a
    }
    if (b!==undefined) {
      second = b
    }
    // console.log({first,second})
    return first + second
  }

  const roleColor = (role) => {
    switch (role) {
      case 'Private':
        return { backgroundColor: '#db3113', color: 'white' };
      case 'Supporter':
        return { backgroundColor: '#265bd6', color: 'white' };
      case 'Public':
        return { backgroundColor: '#008000', color: 'white' };
      case 'Pledger':
        return { backgroundColor: 'ligthbrown', color: 'white' };
      default:
        return { backgroundColor: 'white', color: 'black' };
    }
  };

  const getTraitValue = (attributes,traitType) => {
    const trait = attributes.find(attr => attr.trait_type === traitType);
    return trait ? trait.value : null; // Return null if trait is not found
  };

  return (
    <div>
    <h1>Data Table</h1>
    <table border="1" cellPadding="10" cellSpacing="0">
      <thead>
        <tr>
          <th rowSpan="2"></th>
          <th rowSpan="2"></th>
          <th rowSpan="2" style={{color: "black"}}>Roles</th>
          <th rowSpan="2" style={{color: "black"}}>Size</th>
          <th rowSpan="2" style={{color: "black"}}>Density</th>
          <th colSpan="4" className="trees">Trees</th>
          <th colSpan="4" className="soils">Soils</th>
          <th colSpan="4" className="mines">Mines</th>
          <th colSpan="4" className="woodworking">Woodworking</th>
          <th colSpan="4" className="cooking">Cooking</th>
          <th colSpan="4" className="stoneshaping">Stoneshaping</th>
          <th colSpan="4" className="metalworking">Metalworking</th>
          <th colSpan="6" className="special">Special</th>
        </tr>
        <tr>
          {/* Trees */}
          <th className="trees-opacity-20">T1</th>
          <th className="trees-opacity-40">T2</th>
          <th className="trees-opacity-60">T3</th>
          <th className="trees-opacity-80">T4</th>

          {/* Soils */}
          <th className="soils-opacity-20">T1</th>
          <th className="soils-opacity-40">T2</th>
          <th className="soils-opacity-60">T3</th>
          <th className="soils-opacity-80">T4</th>

          {/* Mines */}
          <th className="mines-opacity-20">T1</th>
          <th className="mines-opacity-40">T2</th>
          <th className="mines-opacity-60">T3</th>
          <th className="mines-opacity-80">T4</th>

          {/* Woodworking */}
          <th className="woodworking-opacity-20">T1</th>
          <th className="woodworking-opacity-40">T2</th>
          <th className="woodworking-opacity-60">T3</th>
          <th className="woodworking-opacity-80">T4</th>

          {/* Cooking */}
          <th className="cooking-opacity-20">T1</th>
          <th className="cooking-opacity-40">T2</th>
          <th className="cooking-opacity-60">T3</th>
          <th className="cooking-opacity-80">T4</th>

          {/* Stoneshaping */}
          <th className="stoneshaping-opacity-20">T1</th>
          <th className="stoneshaping-opacity-40">T2</th>
          <th className="stoneshaping-opacity-60">T3</th>
          <th className="stoneshaping-opacity-80">T4</th>

          {/* Metalworking */}
          <th className="metalworking-opacity-20">T1</th>
          <th className="metalworking-opacity-40">T2</th>
          <th className="metalworking-opacity-60">T3</th>
          <th className="metalworking-opacity-80">T4</th>

          {/* Special */}
          <th className="special-opacity-20">BBQ</th>
          <th className="special-opacity-30">Sub</th>
          <th className="special-opacity-50">Gal</th>
          <th className="special-opacity-70">Slug</th>
          <th className="special-opacity-80">Coop</th>
          <th className="special-opacity-90">Mill</th>
        </tr>
      </thead>
      <tbody>
        {LandInfo.map((row, index) => (
          <tr key={index}>
            <td>{row.land_id}</td>
            <td>{row.permissions.player.username}</td>
            <td style={roleColor(row.permissions.permission)}>{row.permissions.permission}</td>

            <td>{getTraitValue(row.nft_meta_data.attributes, "Size")}</td>
            <td>{getTraitValue(row.nft_meta_data.attributes, "Tree Density")}</td>

            {/* Trees */}
            <td className="trees-opacity-20">{row.industries.trees?.tiers["1"] || "-"}</td>
            <td className="trees-opacity-40">{row.industries.trees?.tiers["2"] || "-"}</td>
            <td className="trees-opacity-60">{row.industries.trees?.tiers["3"] || "-"}</td>
            <td className="trees-opacity-80">{row.industries.trees?.tiers["4"] || "-"}</td>

            {/* Soils */}
            <td className="soils-opacity-20">{row.industries.soils?.tiers["1"] || "-"}</td>
            <td className="soils-opacity-40">{row.industries.soils?.tiers["2"] || "-"}</td>
            <td className="soils-opacity-60">{row.industries.soils?.tiers["3"] || "-"}</td>
            <td className="soils-opacity-80">{row.industries.soils?.tiers["4"] || "-"}</td>

            {/* Mines */}
            <td className="mines-opacity-20">{row.industries?.industries["ent_mine_01"] || "-"}</td>
            <td className="mines-opacity-40">{row.industries?.industries["ent_mine_02"] || "-"}</td>
            <td className="mines-opacity-60">{row.industries?.industries["ent_mine_03"] || "-"}</td>
            <td className="mines-opacity-80">{row.industries?.industries["ent_mine_04"] || "-"}</td>

            {/* Woodworking */}
            <td className="woodworking-opacity-20">{sumIndustries(row.industries?.industries["ent_woodwork_01"], row.industries_h?.industries["ent_woodwork_01"]) || "-"}</td>
            <td className="woodworking-opacity-40">{sumIndustries(row.industries?.industries["ent_woodwork_02"], row.industries_h?.industries["ent_woodwork_02"]) || "-"}</td>
            <td className="woodworking-opacity-60">{sumIndustries(row.industries?.industries["ent_woodwork_03"], row.industries_h?.industries["ent_woodwork_03"]) || "-"}</td>
            <td className="woodworking-opacity-80">{sumIndustries(row.industries?.industries["ent_woodwork_04"], row.industries_h?.industries["ent_woodwork_04"]) || "-"}</td>

            {/* Cooking */}
            <td className="cooking-opacity-20">{row.industries_h?.industries["ent_stove_01"] || "-"}</td>
            <td className="cooking-opacity-40">{row.industries_h?.industries["ent_stove_02"] || "-"}</td>
            <td className="cooking-opacity-60">{row.industries_h?.industries["ent_stove_03"] || "-"}</td>
            <td className="cooking-opacity-80">{row.industries_h?.industries["ent_stove_04"] || "-"}</td>

            {/* Stoneshaping */}
            <td className="stoneshaping-opacity-20">{sumIndustries(row.industries?.industries["ent_kiln_01"], row.industries_h?.industries["ent_kiln_01"]) || "-"}</td>
            <td className="stoneshaping-opacity-40">{sumIndustries(row.industries?.industries["ent_kiln_02"], row.industries_h?.industries["ent_kiln_02"]) || "-"}</td>
            <td className="stoneshaping-opacity-60">{sumIndustries(row.industries?.industries["ent_kiln_03"], row.industries_h?.industries["ent_kiln_03"]) || "-"}</td>
            <td className="stoneshaping-opacity-80">{sumIndustries(row.industries?.industries["ent_kiln_04"], row.industries_h?.industries["ent_kiln_04"]) || "-"}</td>

            {/* Metalworking */}
            <td className="metalworking-opacity-20">{sumIndustries(row.industries?.industries["ent_metalworking_01"], row.industries_h?.industries["ent_metalworking_01"]) || "-"}</td>
            <td className="metalworking-opacity-40">{sumIndustries(row.industries?.industries["ent_metalworking_02"], row.industries_h?.industries["ent_metalworking_02"]) || "-"}</td>
            <td className="metalworking-opacity-60">{sumIndustries(row.industries?.industries["ent_metalworking_03"], row.industries_h?.industries["ent_metalworking_03"]) || "-"}</td>
            <td className="metalworking-opacity-80">{sumIndustries(row.industries?.industries["ent_metalworking_04"], row.industries_h?.industries["ent_metalworking_04"]) || "-"}</td>

            <td className="special-opacity-20">{row.industries?.industries["ent_landbbq"] || "-"}</td>
            <td className="special-opacity-30">{row.industries?.industries["ent_waterbbq"] || "-"}</td>
            <td className="special-opacity-50">{row.industries?.industries["ent_spacebbq"] || "-"}</td>
            <td className="special-opacity-70">{row.industries?.industries["ent_sluggery"] || "-"}</td>
            <td className="special-opacity-80">{row.industries?.industries["ent_coop"] || "-"}</td>
            <td className="special-opacity-90">{row.industries?.industries["ent_windmill"] || "-"}</td>
            {/* Add other data cells as needed */}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  );
};

export default LandsTable;
