import React from 'react';
import { Analytics } from "@vercel/analytics/react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './components/MainPage';
import ItemPage from './components/ItemPage';
import Mainpage2 from './components/Mainpage2';
import RoomTable from './components/tracking_tool/RoomTable'
import LandsTable from './components/lands/LandsTable'
import Room from './components/tracking_tool/Room'
import NotFound from './components/NotFound';
// import Converter from "./test/Converter"
import "./App.css"

const App = () => {
  return (
    <>
    <Router>
      <Routes>
        {/* <Route path="/" element={<MainPage />} /> */}
        {/* <Route path="/item/:id" element={<ItemPage />} />
        <Route path="/" element={<Mainpage2 />} />
        <Route path="/user_history/:id" element={<Mainpage2 />} />
        <Route path="/room_history/:roomId" element={<Mainpage2 />} />
        <Route path="/room" element={<RoomTable />} />
        <Route path="/room/:roomId" element={<Room />} /> */}
        <Route path="/lands/:guild" element={<LandsTable />} />
        <Route component={NotFound} />
        {/* <Route path="/c" element={<Converter />} /> */}
      </Routes>
    </Router>
     <Analytics />
     </>
  );
};

export default App;
