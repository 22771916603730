import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
    MRT_GlobalFilterTextField,
    MRT_TableBodyCellValue,
    MRT_TablePagination,
    MRT_ToolbarAlertBanner,
    flexRender,
    useMaterialReactTable,
  } from 'material-react-table';
  import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from '@mui/material';

import Header from './Header';
import { fetchItems } from '../utils/api';

//nested data is ok, see accessorKeys in ColumnDef below
let pixel_table_data = await fetchItems();

// ['interiorDecorations',
  // 'consumables', 'pets',
  // 'general',
  // 'farming', 'miscellaneous',
  // 'exteriorDecorations', 'crafting',
  // 'tools', 'blueprintsKitsRecipes',
  // 'specialty', 'decoration']
pixel_table_data = pixel_table_data
.filter(a => !a.categories.includes("interiorDecorations"))
.filter(a => !a.categories.includes("exteriorDecorations"))
.filter(a => !a.categories.includes("pets"))
// .filter(a => !a.categories.includes("blueprintsKitsRecipes"))
.filter(a => !a.categories.includes("decoration"))
.filter(a => !a.categories.includes("specialty"))
.filter(a => !a.categories.includes("miscellaneous"))
// .filter(a => !a.categories.includes("miscellaneous"))
.filter(a => !a.name.includes("$APE Coupon"))
// console.log(pixel_table_data[0])
// Function to get the image by id
const getImageById = (arr, id) => {
  const item = arr.find(element => element.id === id);
  return item ? item.image : null;
}

let data = [];
let skill = "", level = "", requirements = "", tier = "";
pixel_table_data.forEach(item => {
    skill = ""; level = ""; requirements = ""; tier = "";
    if(item.id == "itm_metalworking_kit_04"){
      console.log(item)
    }
    if(item.requirements != null && item.requirements.onCrafting != null){
        skill = item.requirements.onCrafting.skill;
        level = item.requirements.onCrafting.level;
        tier = item.requirements.onCrafting.tier;
        skill = skill == null || typeof skill === "undefined" ? "" : skill;
        level = (level == null || (typeof level == "undefined")) ? "" : level;
        tier = (tier == null || (typeof tier == "undefined")) ? "" : tier;
    } else if (item.requirements != null && item.requirements.onUse != null && item.requirements.onUse[0] != null) {
      skill = item.requirements.onUse[0].levelType;
      level = item.requirements.onUse[0].level;
      tier = item.requirements.onUse[0].tier;
      skill = skill == null || typeof skill === "undefined" ? "" : skill;
      level = (level == null || (typeof level == "undefined")) ? "" : level;
      tier = (tier == null || (typeof tier == "undefined")) ? "" : tier;
    }
    if (item.requirements != null && item.requirements.items != null) {
      let item_requirements = item.requirements.items;
      item_requirements.forEach(item_requirements_data => {
        requirements += " "+item_requirements_data.name + ":"+ item_requirements_data.quantity;
      });
    }
    data.push({
        'id': item.id,
        'name': item.name.trim(),
        'image': item.image,
        'tier': tier,
        'skill': skill,
        'level': level,
        'requirements': requirements
    });

});

data = data.sort((a, b) => {
  if (a['name'] < b['name']) {
    return -1;
  }
  if (a['name'] > b['name']) {
    return 1;
  }
  return 0;
});

const Mainpage2 = () => {
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        id: 'name', //id used to define `group` column
        columns: [
          {
            accessorFn: (row) => `${row.name}`, //accessorFn used to join multiple data into a single cell
            id: 'name', //id is still required when using accessorFn instead of accessorKey
            header: 'Name',
            size: 250,
            Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <img
                  height={30}
                  src={row.original.image}
                />
                {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                <span><Link to={`/item/${row.original.id}`}>{renderedCellValue}</Link></span>
              </Box>
            ),
          },
          {
            accessorFn: (row) => `${row.tier}`, //accessorFn used to join multiple data into a single cell
            id: 'tier', //id is still required when using accessorFn instead of accessorKey
            header: 'Tier',
            size: 250,
            enableGlobalFilter: false,
            Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                <span>{renderedCellValue}</span>
              </Box>
            ),
          },
          {
            accessorFn: (row) => `${row.level}`, //accessorFn used to join multiple data into a single cell
            id: 'level', //id is still required when using accessorFn instead of accessorKey
            header: 'Level',
            size: 250,
            enableGlobalFilter: false,
            Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                <span>{renderedCellValue}</span>
              </Box>
            ),
          },
          {
            accessorFn: (row) => `${row.skill}`, //accessorFn used to join multiple data into a single cell
            id: 'skill', //id is still required when using accessorFn instead of accessorKey
            header: 'Skill',
            size: 250,
            enableGlobalFilter: false,
            Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                <span>{renderedCellValue}</span>
              </Box>
            ),
          },
          {
            accessorFn: (row) => `${row.requirements}`, //accessorFn used to join multiple data into a single cell
            id: 'requirements', //id is still required when using accessorFn instead of accessorKey
            header: 'Requirements',
            size: 250,
            enableGlobalFilter: false,
            Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                <span>{renderedCellValue}</span>
              </Box>
            ),
          },
        ],
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    muiTableBodyRowProps: ({ row }) => ({
        onClick: (event) => {
          console.info(event, row.id);
        },
        sx: {
          cursor: 'pointer', //you might want to change the cursor too when adding an onClick
        },
      }),
    initialState: {
      pagination: { pageSize: 15, pageIndex: 0 },
      showGlobalFilter: true,
    },
    //customize the MRT components
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 25, 50],
      variant: 'outlined',
    },
    paginationDisplayMode: 'pages',
  });

  return (
    <><Header />
    <Stack sx={{ m: '2rem 0' }}>


       <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {/**
         * Use MRT components along side your own markup.
         * They just need the `table` instance passed as a prop to work!
         */}
        <MRT_GlobalFilterTextField table={table} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/**
         * Use MRT components along side your own markup.
         * They just need the `table` instance passed as a prop to work!
         */}
        <MRT_TablePagination table={table} />
      </Box>
      {/* Using Vanilla Material-UI Table components here */}
      <TableContainer>
        <Table>
          {/* Use your own markup, customize however you want using the power of TanStack Table */}
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell variant="head" key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.Header ??
                            header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row, rowIndex) => (
              <TableRow key={row.id} selected={row.getIsSelected()}>
                {row.getVisibleCells().map((cell, _columnIndex) => (
                  <TableCell variant="body" key={cell.id}>
                    {/* Use MRT's cell renderer that provides better logic than flexRender */}
                    <MRT_TableBodyCellValue
                      cell={cell}
                      table={table}
                      staticRowIndex={rowIndex} //just for batch row selection to work
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MRT_ToolbarAlertBanner stackAlertBanner table={table} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/**
         * Use MRT components along side your own markup.
         * They just need the `table` instance passed as a prop to work!
         */}
        <MRT_TablePagination table={table} />
      </Box>
    </Stack>
    </>
  );
};

export default Mainpage2;
