import axios from 'axios'
import PixelItems from "../data/pixels_items_ch2_3.json"
const API_URL = process.env.REACT_APP_API_URL

export const fetchItems = async () => {
  // const response = await fetch(API_URL);
  // const data = await response.json();
  return PixelItems;
};

export const fetchItemDetails = async (id) => {
  // const response = await fetch(`${API_URL}/${id}`);
  // const data = await response.json();
  // console.log(PixelItems.filter(a=> a.id === id))
  return Object.values(PixelItems).filter(a=> a.id === id)[0];
};

export const fetchRoom = async (roomId) => {
  const response = await axios.get(`${API_URL}/history/${roomId}`);
  let data = await response.data.data
  data = data
  // .filter(a => a.finishtime !== null)
  .reduce((accumulator, currentValue) => {
    const isDuplicate = accumulator.some(item =>
      item.finishtime === currentValue.finishtime && item.itemid === currentValue.itemid
    );

    if (!isDuplicate) {
      accumulator.push(currentValue);
    }

    return accumulator;
  }, [])
  .filter(a => a.finishtime > Date.now())
  .filter(a => a.finishtime !== '')
  .map(b => {
    let finishTime = b.finishtime
    if(finishTime !== null) {
      console.log(finishTime)
      finishTime = new Date(parseInt(finishTime)).toLocaleString()
    }
    let updatedTime = b.updatedtime
    if(updatedTime !== null) {
      console.log(updatedTime)
      updatedTime = new Date(parseInt(updatedTime)).toLocaleString()
    }
    // console.log(finishTime)
    return {...b,finishtime:finishTime, updatedtime:updatedTime}
  })
  return data;
};

export const fetchLands = async (guild) => {
  const response = await axios.get(`${API_URL}/get_lands_by_ghandle/${guild}`);
  let data = await response.data
  return data;
};


export const fetchHistory = async () => {
  const response = await axios.get(`${API_URL}/history`);
  let data = await response.data.data
  data = data
  .filter(a => a.finishtime !== null)
  .filter(a => a.finishtime !== '')
  const data2 = [
    {
      id: 1, //access nested data with dot notation
      roomid: 12,
      username: 'username',
      finishtime: 1718572162013,
      updatedtime: 1718572162013,
      accessorKey: 'itemid',
      itemidfinishiime: 'itemidfinishiime',
      type: 'type',
      typename: 'typename',
      cattype: 0,
    },
  ]
  return data;
};
